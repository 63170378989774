<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    persistent
  >
    <v-card>
      <v-card-title class="subheading">
        Change Password
      </v-card-title>
      <v-card-text>
        <div>
          Changing your password will log you out of the Admin Portal.
        </div>
        <v-form ref="form">
          <v-container>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    v-bind="pass1Config"
                    v-model="pass1"
                    :append-icon="passVis1 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (passVis1 = !passVis1)"
                    :type="passVis1 ? 'password' : 'text'"
                  />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    v-bind="pass2Config"
                    v-model="pass2"
                    :append-icon="passVis2 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (passVis2 = !passVis2)"
                    :type="passVis2 ? 'password' : 'text'"
                  />
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButton @clicked="close" text="Close" />
        <BaseButton @clicked="save" color="accent" text="Save" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import RuleGenerator from '@/utils/RuleGenerator';

export default {
  name: 'ChangePasswordDialog',
  props: {
    open: Boolean,
  },
  data: () => ({
    pass1: null,
    pass2: null,
    passVis1: true,
    passVis2: true,
    pass1Config: {
      label: 'Password',
      rules: new RuleGenerator('Password')
        .setRequired()
        .setMin(8)
        .setMax(255)
        .setRegEx(/^[\d\w ?!@$^&*-]+$/)
        .getRules(),
      hint: 'Must contain letters, numbers, ?!@$^&*-',
      counter: '255 ',
    },
    pass2Config: {
      label: 'Confirm Password',
      rules: new RuleGenerator('Confirm Password')
        .setRequired()
        .setMin(8)
        .setMax(255)
        .setRegEx(/^[\d\w ?!@$^&*-]+$/)
        .getRules(),
      hint: 'Must contain letters, numbers, ?!@$^&*-',
      counter: '255 ',
    },
  }),
  computed: {
    isOpen() {
      return this.open;
    },
    passwordSame() {
      return this.pass1 === this.pass2;
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    reset() {
      this.pass1 = null;
      this.pass2 = null;
      this.passVis1 = null;
      this.passVis2 = null;
    },
    save() {
      if (!this.passwordSame) {
        this.setAlertMessage({
          type: 'error',
          message: 'Your passwords do not match',
        });
        return false;
      }

      if (this.$refs.form.validate()) {
        this.$emit('save', this.pass1);
      }
      this.reset();
      return true;
    },
    close() {
      this.$emit('closed');
    },
  },
};
</script>
